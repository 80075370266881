import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;

export default function Footer() {
  return (
    <Wrapper>
      <p> &copy; 2021 Jeff Richards</p>
      <p>4701 National Western Drive Denver, CO 80216</p>
      <p>303-718-6916</p>
    </Wrapper>
  );
}
